import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../common/app.constants';

const httpOptions = {
		  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private http: HttpClient) { }

  uploadImage(formData): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'uploadFileContest', formData);
  }

  uploadImageExam(formData): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'uploadFileExam', formData);
  }

  uploadBannerImage(formData): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'uploadBannerFile', formData);
  }

  uploadMedia(formData): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'uploadFileMedia', formData);
  }

  uploadImageBlog(formData): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'uploadFileBlog', formData);
  }

  uploadImageNews(formData): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'uploadFileNews', formData);
  }

  uploadImageUser(formData): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'uploadFileUser', formData);
  }
  uploadMultipleFile(formData): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'uploadMultipleFile', formData);
  }
  uploadFileOtherUser(formData): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'uploadFileOtherUser', formData);
  }
  uploadImageEvent(formData): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'uploadEventsFile', formData);
  }

  getAllFileHinhByMyDistrictId(page, size): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'getFileHinhByDistrictId?page=' + page + '&size=' + size, httpOptions);
  }

  getAllFileTaiLieuByMyDistrictId(page, size): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'getFileTaiLieuByDistrictId?page=' + page + '&size=' + size, httpOptions);
  }
  deleleFileUploadById(id){
    return this.http.post(AppConstants.API_URL + 'fileUpload/delete/' + id, httpOptions);
  }

  uploadImageThuvien(formData): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'uploadFileThuvien', formData);
  }
  uploadFileThuvienItem(formData): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'uploadFileThuvienItem', formData);
  }
  deleteThuVienItem(id): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'libraryDocumentItem/delete/' + id, httpOptions);
  }
  uploadFileLectureDetailDocument(formData): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'uploadFileLearn', formData);
  }

  uploadFileBanAndProfileUser(formData): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'uploadFileBanAndProfileUser', formData);
  }

  deleteFileBanAndProfileUser(formData): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'deleteFileBanAndProfileUser', formData);
  }

  uploadImageVotingCategory(formData): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'uploadVotingCategory', formData);
  }
  uploadCustomImg(formData): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'uploadCustomFile', formData);
  }
}
