import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';
import { CustomImgComponent } from './views/pages/daihoi/customImg/customImg.component';
import { CustomCreateComponent } from './views/pages/daihoi/customCreate/customCreate.component';
import { CustomKyvongComponent } from './views/pages/daihoi/customKyvong/customKyvong.component';
import { CustomAvatarComponent } from './views/pages/daihoi/customAvatar/customAvatar.component';
// import { CustomCreateComponent } from './views/pages/daihoi/customImg/create/customImg-create.component';


const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'tai-khoan',
        loadChildren: () => import('./views/pages/daihoi/account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'tin-tuc',
        loadChildren: () => import('./views/pages/daihoi/news/news.module').then(m => m.NewsHuyenTPModule)
      },
      {
        path: 'tai-lieu',
        loadChildren: () => import('./views/pages/daihoi/gallary/gallary.module').then(m => m.GallaryModule)
      },
      {
        path: 'de-thi',
        loadChildren: () => import('./views/pages/daihoi/exam/exam.module').then(m => m.ExamModule)
      },
      {
        path: 'dai-bieu',
        loadChildren: () => import('./views/pages/daihoi/addUser/addUser.module').then(m => m.AddUserModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./views/pages/daihoi/addUsers/users.module').then(m => m.UsersModule)
      },
      {
        path: 'chuong-trinh',
        loadChildren: () => import('./views/pages/daihoi/chuonngTrinh/chuong-trinh.module').then(m => m.ChuongTrinhModule)
      },
      {
        path: 'daibieu-thamgia',
        loadChildren: () => import('./views/pages/daihoi/daibieuthamgia/dsdaibieu.module').then(m => m.DsdaibieuModule)
      },
      {
        path: 'so-do-vi-tri',
        loadChildren: () => import('./views/pages/daihoi/location-map/location-map.module').then(m => m.LocationMapModule)
      },
      {
        path: 'voting',
        loadChildren: () => import('./views/pages/daihoi/voting/voting.module').then(m => m.VotingModule)
      },
      {
        path: 'results',
        loadChildren: () => import('./views/pages/daihoi/results/results.module').then(m => m.ResultsModule)
      },
      {
        path: 'banner',
        loadChildren: () => import('./views/pages/daihoi/banner/banner.module').then(m => m.BannerModule)
      },
      {
        path: 'avatar-framer',
        loadChildren: () => import('./views/pages/daihoi/avatar-framer/avatar-framer.module').then(m => m.AvatarFramerModule)
      },
      {
        path: 'convert-img',
        loadChildren: () => import('./views/pages/daihoi/convertImg/convertImg.module').then(m => m.ConvertImgModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'apps',
        loadChildren: () => import('./views/pages/apps/apps.module').then(m => m.AppsModule)
      },
      {
        path: 'ui-components',
        loadChildren: () => import('./views/pages/ui-components/ui-components.module').then(m => m.UiComponentsModule)
      },
      {
        path: 'advanced-ui',
        loadChildren: () => import('./views/pages/advanced-ui/advanced-ui.module').then(m => m.AdvancedUiModule)
      },
      {
        path: 'form-elements',
        loadChildren: () => import('./views/pages/form-elements/form-elements.module').then(m => m.FormElementsModule)
      },
      {
        path: 'advanced-form-elements',
        loadChildren: () => import('./views/pages/advanced-form-elements/advanced-form-elements.module').then(m => m.AdvancedFormElementsModule)
      },
      {
        path: 'charts-graphs',
        loadChildren: () => import('./views/pages/charts-graphs/charts-graphs.module').then(m => m.ChartsGraphsModule)
      },
      {
        path: 'tables',
        loadChildren: () => import('./views/pages/tables/tables.module').then(m => m.TablesModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/pages/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'general',
        loadChildren: () => import('./views/pages/general/general.module').then(m => m.GeneralModule)
      },
      { path: '', redirectTo: 'tai-khoan', pathMatch: 'full' },
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
      {
        path: 'custom',
        loadChildren: () => import('./views/pages/daihoi/customImg/customImg.module').then(m => m.CustomImgModule)
      },
      
      {
        path: 'custom/create',
        loadChildren: () => import('./views/pages/daihoi/customCreate/customCreate.module').then(m => m.CustomCreateModule)
      },

    ]
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      'type': 404,
      'title': 'Page Not Found',
      'desc': 'Oopps!! The page you were looking for doesn\'t exist.'
    }
  },
  {
    path: 'custom/:slug',
    component: CustomKyvongComponent,
    data: {
      title: 'Tạo vị trí'
    },
  },
  {
    path: 'avatar/:slug',
    component: CustomAvatarComponent,
    data: {
      title: 'Tạo vị trí'
    },
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
