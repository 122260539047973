export const IP = 'app.hoilhtn.tinhdoandongnai.com';
// export const IP = 'localhost';
export const PORT = '1003'

export class AppConstants {
    private static API_BASE_URL = 'http://' + IP + ':' + PORT + '/';
    private static API_BASE_URL2 = 'http://' + IP + ':' + PORT;
    public static API_URL = AppConstants.API_BASE_URL + "api/";
    public static API_URL2 = AppConstants.API_BASE_URL2;
    public static AUTH_API = AppConstants.API_URL + "auth/";
    public static IP_PORT = IP + ':' + PORT;
}