import { MenuCOSOItem } from './menuCOSO.model';

export const MENUCOSO: MenuCOSOItem[] = [
  {
    label: 'Main',
    isTitle: true
  },
  {
    label: 'Tài khoản',
    icon: 'user',
    link: '/tai-khoan'
  },

  {
    label: 'Đại hội',
    icon: 'calendar',
    subItems: [
      {
        label: 'Chương trình',
        link: '/chuong-trinh',
      },
      {
        label: 'Banner',
        link: '/banner'
      },
      {
        label: 'Tạo ảnh',
        link: '/custom'
      },
      {
        label: 'Tin tức',
        link: '/tin-tuc'
      },
      {
        label: 'Tài liệu',
        link: '/tai-lieu'
      },
      {
        label: 'Đề thi',
        link: '/de-thi'
      },
      {
        label: 'Sơ đồ vị trí',
        link: '/so-do-vi-tri'
      },
      {
        label: 'Đại biểu tham gia',
        link: '/daibieu-thamgia'
      },
      {
        label: 'Biểu quyết',
        link: '/voting'
      },
      {
        label: 'Kết quả đại hội',
        link: '/results'
      },
    ]
  },
  // {
  //   label: 'Quản lý Tài khoản',
  //   icon: 'users',
  //   link: '/users'
  // },
  {
    label: 'Quản lý Đại biểu',
    icon: 'users',
    link: '/dai-bieu'
  },
  
];
